import { z } from 'zod'

export const NBA_TEAMS = [
	{
		id: '15',
		nbaId: '1610612737',
		abbreviation: 'ATL',
		teamName: 'Atlanta Hawks',
		simpleName: 'Hawks',
		location: 'Atlanta',
		icon: 'hawks-logo',
	},
	{
		id: '16',
		nbaId: '1610612738',
		abbreviation: 'BOS',
		teamName: 'Boston Celtics',
		simpleName: 'Celtics',
		location: 'Boston',
		icon: 'celtics-logo',
	},
	{
		id: '29',
		nbaId: '1610612751',
		abbreviation: 'BKN',
		teamName: 'Brooklyn Nets',
		simpleName: 'Nets',
		location: 'Brooklyn',
		icon: 'nets-logo',
	},
	{
		id: '44',
		nbaId: '1610612766',
		abbreviation: 'CHA',
		teamName: 'Charlotte Hornets',
		simpleName: 'Hornets',
		location: 'Charlotte',
		icon: 'hornets-logo',
	},
	{
		id: '19',
		nbaId: '1610612741',
		abbreviation: 'CHI',
		teamName: 'Chicago Bulls',
		simpleName: 'Bulls',
		location: 'Chicago',
		icon: 'bulls-logo',
	},
	{
		id: '17',
		nbaId: '1610612739',
		abbreviation: 'CLE',
		teamName: 'Cleveland Cavaliers',
		simpleName: 'Cavaliers',
		location: 'Cleveland',
		icon: 'cavs-logo',
	},
	{
		id: '20',
		nbaId: '1610612742',
		abbreviation: 'DAL',
		teamName: 'Dallas Mavericks',
		simpleName: 'Mavericks',
		location: 'Dallas',
		icon: 'mavericks-logo',
	},
	{
		id: '21',
		nbaId: '1610612743',
		abbreviation: 'DEN',
		teamName: 'Denver Nuggets',
		simpleName: 'Nuggets',
		location: 'Denver',
		icon: 'nuggets-logo',
	},
	{
		id: '43',
		nbaId: '1610612765',
		abbreviation: 'DET',
		teamName: 'Detroit Pistons',
		simpleName: 'Pistons',
		location: 'Detroit',
		icon: 'pistons-logo',
	},
	{
		id: '22',
		nbaId: '1610612744',
		abbreviation: 'GSW',
		teamName: 'Golden State Warriors',
		simpleName: 'Warriors',
		location: 'Golden State',
		icon: 'warriors-logo',
	},
	{
		id: '23',
		nbaId: '1610612745',
		abbreviation: 'HOU',
		teamName: 'Houston Rockets',
		simpleName: 'Rockets',
		location: 'Houston',
		icon: 'rockets-logo',
	},
	{
		id: '32',
		nbaId: '1610612754',
		abbreviation: 'IND',
		teamName: 'Indiana Pacers',
		simpleName: 'Pacers',
		location: 'Indiana',
		icon: 'pacers-logo',
	},
	{
		id: '24',
		nbaId: '1610612746',
		abbreviation: 'LAC',
		teamName: 'Los Angeles Clippers',
		simpleName: 'Clippers',
		location: 'Los Angeles',
		icon: 'clippers-logo',
	},
	{
		id: '25',
		nbaId: '1610612747',
		abbreviation: 'LAL',
		teamName: 'Los Angeles Lakers',
		simpleName: 'Lakers',
		location: 'Los Angeles',
		icon: 'lakers-logo',
	},
	{
		id: '41',
		nbaId: '1610612763',
		abbreviation: 'MEM',
		teamName: 'Memphis Grizzlies',
		simpleName: 'Grizzlies',
		location: 'Memphis',
		icon: 'grizzlies-logo',
	},
	{
		id: '26',
		nbaId: '1610612748',
		abbreviation: 'MIA',
		teamName: 'Miami Heat',
		simpleName: 'Heat',
		location: 'Miami',
		icon: 'heat-logo',
	},
	{
		id: '27',
		nbaId: '1610612749',
		abbreviation: 'MIL',
		teamName: 'Milwaukee Bucks',
		simpleName: 'Bucks',
		location: 'Milwaukee',
		icon: 'bucks-logo',
	},
	{
		id: '28',
		nbaId: '1610612750',
		abbreviation: 'MIN',
		teamName: 'Minnesota Timberwolves',
		simpleName: 'Timberwolves',
		location: 'Minnesota',
		icon: 'timberwolves-logo',
	},
	{
		id: '18',
		nbaId: '1610612740',
		abbreviation: 'NOP',
		teamName: 'New Orleans Pelicans',
		simpleName: 'Pelicans',
		location: 'New Orleans',
		icon: 'pelicans-logo',
	},
	{
		id: '30',
		nbaId: '1610612752',
		abbreviation: 'NYK',
		teamName: 'New York Knicks',
		simpleName: 'Knicks',
		location: 'New York',
		icon: 'knicks-logo',
	},
	{
		id: '38',
		nbaId: '1610612760',
		abbreviation: 'OKC',
		teamName: 'Oklahoma City Thunder',
		simpleName: 'Thunder',
		location: 'Oklahoma City',
		icon: 'okc-logo',
	},
	{
		id: '31',
		nbaId: '1610612753',
		abbreviation: 'ORL',
		teamName: 'Orlando Magic',
		simpleName: 'Magic',
		location: 'Orlando',
		icon: 'magic-logo',
	},
	{
		id: '33',
		nbaId: '1610612755',
		abbreviation: 'PHI',
		teamName: 'Philadelphia 76ers',
		simpleName: '76ers',
		location: 'Philadelphia',
		icon: '76ers-logo',
	},
	{
		id: '34',
		nbaId: '1610612756',
		abbreviation: 'PHX',
		teamName: 'Phoenix Suns',
		simpleName: 'Suns',
		location: 'Phoenix',
		icon: 'suns-logo',
	},
	{
		id: '35',
		nbaId: '1610612757',
		abbreviation: 'POR',
		teamName: 'Portland Trail Blazers',
		simpleName: 'Trail Blazers',
		location: 'Portland',
		icon: 'blazers-logo',
	},
	{
		id: '36',
		nbaId: '1610612758',
		abbreviation: 'SAC',
		teamName: 'Sacramento Kings',
		simpleName: 'Kings',
		location: 'Sacramento',
		icon: 'kings-logo',
	},
	{
		id: '37',
		nbaId: '1610612759',
		abbreviation: 'SAS',
		teamName: 'San Antonio Spurs',
		simpleName: 'Spurs',
		location: 'San Antonio',
		icon: 'spurs-logo',
	},
	{
		id: '39',
		nbaId: '1610612761',
		abbreviation: 'TOR',
		teamName: 'Toronto Raptors',
		simpleName: 'Raptors',
		location: 'Toronto',
		icon: 'raptors-logo',
	},
	{
		id: '40',
		nbaId: '1610612762',
		abbreviation: 'UTA',
		teamName: 'Utah Jazz',
		simpleName: 'Jazz',
		location: 'Utah',
		icon: 'jazz-logo',
	},
	{
		id: '42',
		nbaId: '1610612764',
		abbreviation: 'WAS',
		teamName: 'Washington Wizards',
		simpleName: 'Wizards',
		location: 'Washington',
		icon: 'wizards-logo',
	},
] as const

export const teamsById = NBA_TEAMS.reduce(
	(obj, item) => ({
		...obj,
		[item.id]: item,
	}),
	{} as Record<TeamId, Team>,
)

export type Team = (typeof NBA_TEAMS)[number]

export const NBATeamIdSchema = z.enum(
	NBA_TEAMS.map(({ id }) => id) as [Team['id'], ...Team['id'][]],
)

export type TeamId = z.infer<typeof NBATeamIdSchema>
